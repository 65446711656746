<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.mobile"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        outlined
        style="width: 50%; margin-right: -1px"
        class="py-6 rounded-lg rounded-r-0"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>mdi-magnify</v-icon> Buscar
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="pt-3 px-3"
        >
        <v-text-field
          outlined
          v-model="search"
          class="rounded-0"
          color="secondary"
          append-icon="mdi-magnify"
          @click:append="fetchProducts"
          @keydown.enter="fetchProducts"
          label="Buscar productos"
          hide-details
          ></v-text-field>
      </v-card-title>

      <v-card-text
        class="px-0"
        >
        <v-subheader
          >Resultados</v-subheader>
        <template
          v-if="products.length"
          v-for="(product, i) in products"
          >
          <product
            :result="product"
            ></product>
          <v-divider></v-divider>
        </template>
        <v-alert
          v-if="!products.length"
          type="info"
          tile
          >
          No se encontraron resultados.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { SearchProducts } from '@/graphql/queries/products'

const Product = () => import('@/components/search/Product')

export default {
  data: () => ({
    dialog: false,
    search: null,
    products: []
  }),

  props: {
    store: {
      required: true,
      type: Object
    }
  },

  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        this.dialog = false

        next(false)
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  methods: {
    fetchProducts () {
      if (this.search) {
        this.$apollo.query({
          query: SearchProducts,
          variables: {
            search: this.search,
            storeId: this.store.id,
            limit: 10
          }
        }).then ( res => {
          this.products = res.data.searchProducts
        })
      }
    }
  },

  components: { Product }
}
</script>
